// Supported plugins configuration
GLOBAL.config = {

  // Replace checkboxes and radio buttons
  inputs: $('[type="checkbox"]'),

  // Replace selectors
  selectors: $(),

  // Carousels
  carousels: {
    '#guestbook': {
      wrap: 'circular',
      scrollableItems: 1,
      autoscrollInterval: 0, // ms
      pagination: false,
      touchable: GLOBAL.isTouchscreen
    }
  },

  // Modal windows
  modals: {
    '.nav-toolbar-login, .private-login': {
      node: '#private-login',
      fixedElements: 'header'
    },
    '.nav-toolbar-register, .private-register': {
      node: '#private-register',
      fixedElements: 'header'
    },
    '.private-forget': {
      node: '#private-forget',
      fixedElements: 'header'
    },
    '.private-reset': {
      node: '#private-reset',
      fixedElements: 'header'
    }
  },

  // Popups
  popups: {
    /*'#cat-item-action-faq': {
      rel: '#popup-faq',
      posTop: {
        value: 'under + 10',
        auto: false
      },
      posLeft: {
        value: 'center - 31',
        auto: false
      },
      manipulation: true,
      effect: false
    }*/
  },

  // Tabs
  tabs: {
    /*'#product-functions': {
      updateHash: false
    }*/
  }

};